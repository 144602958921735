@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .fill-blue-300 svg {
        fill: #2B205C;
    }

    .stroke-blue-300 svg {
        stroke: #2B205C;
    }

    .thumb,
    .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        outline: none;
        z-index: 10;
    }

    .thumb::-webkit-slider-thumb {
        background-color: #FFD400;
        border: 1px solid #FF5574;
        border-radius: 50%;
        cursor: pointer;
        height: 12px;
        width: 12px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }

    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
        background-color: #FFD400;
        border: 1px solid #FF5574;
        border-radius: 50%;
        cursor: pointer;
        height: 12px;
        width: 12px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }

    .main-height {
        height: calc(100% - 118px);
    }

    .b b {
        font-family: 'Inter SemiBold', sans-serif;
    }

    .tinder-list-height {
        height: calc(100% - 100px);
    }
}

@font-face {
    font-family: 'Inter Regular';
    src: url('../public/static/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Medium';
    src: url('../public/static/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Inter SemiBold';
    src: url('../public/static/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Bold';
    src: url('../public/static/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Inter ExtraBold';
    src: url('../public/static/fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    background: white;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    font-family: 'Inter Regular', sans-serif;
}

*:focus {
    outline: 0;
}
